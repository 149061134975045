import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Image from "gatsby-image";
import { graphql } from "gatsby";

class ProductsPage extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <Layout>
        <SEO title="Products" />
        <div className="twelve columns content">
          <div className="product">
            <div className="product-image">
              <ul>
                <li>
                  <a
                    href="https://toporace.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      fluid={data.toporace.childImageSharp.fluid}
                      alt="TopoRace.com screenshot"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <h3>TopoRace.com</h3>
            <p className="product-link">
              <a
                href="https://toporace.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://toporace.com
              </a>
            </p>
            <p>TopoRace.com - Beautiful maps from your adventures.</p>
            <div className="clear"></div>
          </div>

          <div className="product">
            <div className="product-image">
              <ul>
                <li>
                  <a
                    href="https://fountainpen.io"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      fluid={data.fountainpen.childImageSharp.fluid}
                      alt="FountainPen.io screenshot"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <h3>FountainPen.io</h3>
            <p className="product-link">
              <a
                href="https://fountainpen.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://fountainpen.io
              </a>
            </p>
            <p>FountainPen.io - journaling simplified.</p>
            <div className="clear"></div>
          </div>

          <div className="product">
            <div className="product-image">
              <ul>
                <li>
                  <a
                    href="https://www.mountainsweekly.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      fluid={data.mountains_weekly.childImageSharp.fluid}
                      alt="MountainsWeekly.com screenshot"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <h3>MountainsWeekly.com</h3>
            <p className="product-link">
              <a
                href="https://www.mountainsweekly.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://www.mountainsweekly.com
              </a>
            </p>
            <p>
              For people who love the mountains. A free, once-weekly, round-up
              of happenings in the Colorado Mountains, highlighting the best of
              what's going on for the upcoming week.
            </p>
            <div className="clear"></div>
          </div>

          <div className="product">
            <div className="product-image">
              <ul>
                <li>
                  <a
                    href="https://lowvolley.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      fluid={data.low_volley.childImageSharp.fluid}
                      alt="LowVolley.com screenshot"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <h3>LowVolley.com</h3>
            <p className="product-link">
              <a
                href="https://lowvolley.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://lowvolley.com
              </a>
            </p>
            <p>
              LowVolley.com - real-time tennis scoring software designed for
              three types of users - teams, tournaments, and individuals.
            </p>
            <div className="clear"></div>
          </div>

          <div className="product">
            <div className="product-image">
              <ul>
                <li>
                  <a
                    href="https://vocabbuilder.netlify.app/lists/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      fluid={data.vocab_builder.childImageSharp.fluid}
                      alt="VocabBuilder screenshot"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <h3>Vocab Builder</h3>
            <p className="product-link">
              <a
                href="https://vocabbuilder.netlify.app/lists/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://vocabbuilder.netlify.app
              </a>
            </p>
            <p>
              A flashcard-like learning system designed to quickly create self
              administered quizes. A great way to learn any type of fact.
            </p>
            <div className="clear"></div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ProductsPage;

export const pageQuery = graphql`
  query {
    toporace: file(absolutePath: { regex: "/toporace.png/" }) {
      childImageSharp {
        fluid(maxWidth: 2108) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fountainpen: file(absolutePath: { regex: "/fountainpen.png/" }) {
      childImageSharp {
        fluid(maxWidth: 2737) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mountains_weekly: file(absolutePath: { regex: "/mountains_weekly.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1540) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    low_volley: file(absolutePath: { regex: "/low_volley.png/" }) {
      childImageSharp {
        fluid(maxWidth: 2190) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vocab_builder: file(absolutePath: { regex: "/vocab_builder_app.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1851) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
